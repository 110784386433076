import { OrLoaderV3 as OrLoader, LoaderVariant } from '@onereach/ui-components';
import { storeToRefs } from 'pinia';
import { computed, defineComponent } from 'vue';
import { useI18n, useRouteMeta } from '@/composables';
import { MigrationsStatus } from '@/constants';
import useContactsBooksStoreV1 from '@/stores/contactsBooks/contactsBooksV1';
export default defineComponent({
    name: 'MigrationsLoader',
    components: {
        OrLoader,
    },
    setup() {
        const contactsStore = useContactsBooksStoreV1();
        const { migrationsStatus } = storeToRefs(contactsStore);
        const { t } = useI18n();
        const { isV2 } = useRouteMeta();
        const description = computed(() => {
            switch (migrationsStatus.value) {
                case MigrationsStatus.UNDEFINED:
                    return t('contactBooks.pendingMigrationsStatus');
                case MigrationsStatus.IN_PENDING:
                    return t('contactBooks.migratingContactsDesc');
                default:
                    return undefined;
            }
        });
        return {
            description,
            MigrationsStatus,
            LoaderVariant,
            migrationsStatus,
            isV2,
        };
    },
});
